import { withStyles } from '@mui/styles';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import React from 'react';
import './category-tabs.scss';
import { pushDataLayerEvent } from '../../../utils/metricManagementUtil'

const datalayerMenuButtons = (categoryName, subCategory, item) => {      
  let location=''
  if (typeof window !== 'undefined') {
      location= window.location.href
  }
  pushDataLayerEvent({
    event: 'Interacciones_Menú',
    category: categoryName,
    action: 'click',
    label: subCategory || 'Ninguno',
    sub_category: subCategory || 'Ninguno',
    location: location,
    user_data: [],
    log_status: '',
  })
  pushDataLayerEvent({
    event: 'menu_click',
    action_type: 'Link',
    link_text: categoryName+' > '+subCategory,
    index: '6.'+(item+1),
    menu_name: 'Submenú'
  })
}

const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > div': {
      borderRadius: '50%',
      maxWidth: 6,
      width: '100%',
    },
  },
})(props => <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />);

const CategoryTabs = ({ categories, defaultCategory, onChangeTab }) => {
  //activeCategory controller
  const [activeCategory, setActiveCategory] = React.useState(0);

  const handleChangeOption = ({ option, index }) => {
    setActiveCategory(index);
    onChangeTab && onChangeTab(option);
  };
  
  React.useEffect(()=>{
    setActiveCategory(parseInt(defaultCategory || 0))
  }, [])

  return (
    <div className="f-category-tabs">
      <StyledTabs
        className="f-tabs-category"
        value={activeCategory}
        textColor="secondary"
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs example"
      >
        {categories.map((option, index) => {
          return (
            <Tab
              component={React.forwardRef((props, ref) => (
                <a
                  {...props}
                  href={option.redirection || ''}
                  onClick={() => {
                    datalayerMenuButtons('FamiTips', option.value, index)
                    !option.redirection && handleChangeOption({ option, index })
                  }}
                >
                  <span className="MuiTab-wrapper" ref={ref}>
                    {option.value}
                  </span>
                  <span className="MuiTouchRipple-root"></span>
                </a>
              ))}
              key={index}
              id={option.id}
            />
          );
        })}
      </StyledTabs>
    </div>
  );
};

export default CategoryTabs;
