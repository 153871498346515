import React, { useState } from 'react';
import SEO from '../../../shared/components/seo';
import { siteUrl } from '../../../shared/utils/siteUrl';
import Layout from '../../organisms/layout/layout';
import FamiTipsContent from '../../organisms/famitipsa-content/famitipsa-content';
import ModalRegister from '../../molecules/modal-register/modal-register';

const FamitipsTemplate = props => {
  const { pageContext, path } = props;
  const [modalActive, setmodalActive] = useState(null);
  const menuActive = 4;
  const filterArticlesByCategory = category =>
    pageContext.articles.filter(
      article =>
        article.categoriaDelArticulo[0].description.description.toLowerCase() ===
        category.toLowerCase()
    );

  const categoryArticles =
    pageContext.activeCategory === 'Todos'
      ? pageContext.articles
      : filterArticlesByCategory(pageContext.activeCategory);

    return (
    <Layout
      menuActive={menuActive}
      setmodalActive={setmodalActive}
      modalActive={modalActive}
      itemSubMenu={pageContext.activeCategory}
      title={pageContext.metaTitle}
      slug={path}
    >
      <SEO
        title={pageContext.categories[pageContext.category].metaTitulo ? pageContext.categories[pageContext.category].metaTitulo : pageContext.metaTitle}
        description={pageContext.categories[pageContext.category].metaDescripcion ? pageContext.categories[pageContext.category].metaDescripcion : pageContext.metaDescription?.metaDescription}
      />
      <FamiTipsContent
        parentSlug={siteUrl + '/' + pageContext.pageSlug}
        categories={pageContext.categories || []}
        activeCategory={pageContext.activeCategory}
        articles={categoryArticles}
      />
      <ModalRegister />
    </Layout>
  );
};

export default FamitipsTemplate;
