import './famitipsa-content.scss';
import React, { useState } from 'react';
import SectionCardArticles from '../../molecules/section-card-articles/section-card-articles';
import FilterTypeArticle from '../../molecules/filter-type-article/filter-type-article';
import CategoryTabs from '../../../shared/components/molecules/category-tabs/category-tabs';
import Fab from '@mui/material/Fab'

const FamiTipsContent = ({
  categories,
  activeCategory,
  articles,
  parentSlug = '',
}) => {
  const [articlesShow, setarticlesShow] = useState(6);
  const [articlesFilterCategory, setarticlesFilterCategory] = useState(
    articles.filter(
      info => info.tipoDelArticulo.toLowerCase() !== 'poopcast'
    ))
  //each element to format {value, redirection}
  const categoriesMapped = categories.map(category => {
    const categoryName = category.description?.description;
    const categoryNameLowered = categoryName.toLowerCase().trim().replace(/ /g, "-");

    return {
      value: categoryName,
      redirection: parentSlug + ( categoryNameLowered === "todos" ? '' : categoryNameLowered+'/'),
    };
  });

  const indexActiveCategory = categoriesMapped.findIndex(
    ({ value }) => value === activeCategory
  );

  const onChangeTab = option => {
    window.location.href = option.redirection;
  };

  //change type article
  const onTypeChange = (type, customArticles) => {
    let articlesToFilter = customArticles || articles;

    if (type !== 'Todos los formatos') {
      setarticlesFilterCategory(
        articlesToFilter.filter(
          info => info.tipoDelArticulo.toLowerCase() === type.toLowerCase()
        )
      );
    } else {
      setarticlesFilterCategory(articlesToFilter.filter(
        info => info.tipoDelArticulo.toLowerCase() !== 'poopcast'
      ));
    }
  };
  //end change type article
  const handleArticlePlus = () => {
    setarticlesShow(articlesShow + 6);
  };
  
  return (
    <>
      <div className="f-we-tabs">
        <CategoryTabs
          categories={categoriesMapped}
          defaultCategory={indexActiveCategory}
          onChangeTab={onChangeTab}
          activeCategory={activeCategory}
        />
      </div>
      <div className="f-articles-content">
        <div>
          {articlesFilterCategory.length > 0 ? (
            <>
              <SectionCardArticles
                articlesList={articlesFilterCategory.slice(0, articlesShow)}
                isFamiTips={true}
              />
              {articlesShow < articlesFilterCategory.length && (
                <Fab
                  variant="extended"
                  size="large"
                  aria-label="add"
                  className="f-articles-plus"
                  onClick={handleArticlePlus}
                >
                  CARGAR MÁS
                </Fab>
              )}
            </>
          ) : (
            <div className="f-articles-content-notfount">
              No se encontraron artículos para tu búsqueda
            </div>
          )}
        </div>

        <FilterTypeArticle onTypeChange={onTypeChange} />
      </div>
    </>
  );
};

export default FamiTipsContent;
