import React, { useState } from 'react';
import './filter-type-article.scss';
import ositoImg from './../../../assets/images/osito.png';
import useMobileMode from '../../../hooks/useMobileMode';
import { pushDataLayerEvent } from '../../../shared/utils/metricManagementUtil';
import { PoopcastModal } from '../poopcast-modal/poopcast-modal';
import { FAMITIPS_IS_ADULT } from '../../../shared/constants/project-constants';

const FilterTypeArticle = ({ onTypeChange }) => {
  const { isMobile } = useMobileMode();

  const initialOrder = [
    'Todos los formatos',
    'Artículos',
    'Videos',
    'Paso a paso',
    'Tips',
    'Poopcast',
  ];
  const initialOrderCMS = [
    'Todos los formatos',
    'Artículo',
    'Video',
    'Paso a paso',
    'Tips',
    'Poopcast',
  ];

  const [isOpened, setIsOpened] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [optionSelected, setOptionSelected] = useState(initialOrder[0]);

  const handleChangeMenuOption = (index) => {
    const isRemember = localStorage.getItem(FAMITIPS_IS_ADULT);
    const poopCast = initialOrder[index].includes('Poopcast');
    if (poopCast && !isRemember) return setModalIsOpen(true);

    setOptionSelected(initialOrder[index]);
    setIsOpened(false);
    onTypeChange(initialOrderCMS[index]);
  };

  const handleChangeMenuOptionMobile = (op) => {
    const option = initialOrder.indexOf(op);
    if (option !== -1) {
      handleChangeMenuOption(option);
    }
  };

  const handleModalClose = (navigate = false, saveToLocalStorage = false) => {
    setModalIsOpen(false);

    if (saveToLocalStorage) localStorage.removeItem(FAMITIPS_IS_ADULT);

    if (!navigate) return;

    const poopCastIndex = initialOrder.indexOf('Poopcast');
    setOptionSelected(initialOrder[poopCastIndex]);
    setIsOpened(false);
    onTypeChange(initialOrderCMS[poopCastIndex]);
  };

  const datalayerVerticalMenu = (labelText) => {
    const location = typeof window !== 'undefined' ? window.location.href : '';
    pushDataLayerEvent({
      event: 'Interacciones_Artículos',
      category: 'menu_vertical',
      action: 'click',
      label: labelText,
      sub_category: 'Todos',
      location: location,
      user_data: [],
      log_status: '',
    });
  };

  const menuItems = initialOrder.map((op, index) => (
    <li key={index}>
      <p
        className={optionSelected === op ? 'active' : ''}
        onClick={() => {
          handleChangeMenuOption(index);
          datalayerVerticalMenu(op);
        }}
      >
        {op}
      </p>
    </li>
  ));

  const mobileRender = (
    <div className="f-container-absolute">
      <div
        className={'f-menu-mobile-container'}
        style={{ height: isOpened && `${32 * initialOrder.length + 16}px` }}
        onClick={() => !isOpened && setIsOpened(true)}
      >
        <i
          className={`f-icon-collapse fal fa-angle-down ${
            isOpened ? 'f-rotate-arrow' : ''
          }`}
          onClick={() => setIsOpened(!isOpened)}
        ></i>
        <div className="f-option-menu">{optionSelected}</div>
        {initialOrder.map((op) => (
          <div
            className={
              optionSelected === op ? 'f-option-menu disable' : 'f-option-menu'
            }
            key={op}
            onClick={() => handleChangeMenuOptionMobile(op)}
          >
            {op}
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <>
      <PoopcastModal handleClose={handleModalClose} open={modalIsOpen} />
      <div className="f-filter-type">
        <img src={ositoImg} alt="osito familia" />
        <div className="f-filter-type-items">
          <p className="f-filter-type-items-copy">¿Qué quieres ver?</p>
          {isMobile ? mobileRender : <ul>{menuItems}</ul>}
        </div>
      </div>
    </>
  );
};

export default FilterTypeArticle;
