import './poopcast-modal.scss';
import Dialog from '@mui/material/Dialog';
import React from 'react';
import useMobileMode from '../../../hooks/useMobileMode';
import OsoFelix from '../../../assets/images/felix_tattoo_desktop.webp';
import OsoFelixMobile from '../../../assets/images/felix_tattoo_mobile.webp';
import { FAMITIPS_IS_ADULT } from '../../../shared/constants/project-constants';

const PoopcastModal = ({ open = false, handleClose }) => {
  const { isMobile } = useMobileMode();

  const rememberUserChoose = (event) => {
    const isRemember = event.target.checked;

    if (isRemember) {
      localStorage.setItem(FAMITIPS_IS_ADULT, 'true');
    } else {
      localStorage.removeItem(FAMITIPS_IS_ADULT);
    }
  };

  return (
    <Dialog open={open} onClose={() => handleClose()}>
      <section
        className={`poopcast__modal ${
          !isMobile ? 'poopcast__modal--desktop' : ''
        }`}
      >
        <img
          alt="Oso Félix Familia"
          className="poopcast__felix-img"
          height={272}
          src={isMobile ? OsoFelixMobile : OsoFelix}
          width={265}
        />

        <article className="poopcast__article">
          <h2 className="poopcast__title">¿Eres mayor de edad?</h2>

          <p className="poopcast__text">
            Recuerda que para acceder al contenido debes ser mayor de edad.
          </p>

          <div className="poopcast__buttons">
            <button
              className="poopcast__button"
              onClick={() => {
                handleClose(true);
              }}
            >
              Sí
            </button>
            <button
              className="poopcast__button poopcast__button--empty"
              onClick={() => {
                handleClose(false, true);
              }}
            >
              No
            </button>
          </div>

          <form className="poopcast__remember-data">
            <input
              onChange={() => rememberUserChoose(event)}
              type="checkbox"
              name="rememberMe"
              id="rememberMe"
            />
            <label htmlFor="rememberMe">Recordar mis datos*</label>
          </form>

          <footer className="poopcast__footer">
            <p className="poopcast__footer-text">
              *No selecciones esta opción si compartes este dispositivo con
              menores de edad
            </p>
          </footer>
        </article>
      </section>
    </Dialog>
  );
};
export { PoopcastModal };
