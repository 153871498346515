import React,{useState} from 'react';
import Dialog from '@mui/material/Dialog';
import RegisterModal from '../../../assets/images/registro-modal.png';
import './modal-register.scss'
import { LocalStorage } from '../../../shared/utils/storage';

const ModalRegister = () => {
    const handleModalState =()=>{
        try {
            LocalStorage.getItem("MODAL_OPENED")
            if(JSON.parse(resp) === false)return false
            return true
        }
        catch(e) {
            console.log(e)
        }
    }
    const [isOpen,setIsOpen]=useState(()=>handleModalState());
    const handleChange=()=>{
      setIsOpen(false);
      LocalStorage.setItem('MODAL_OPENED',false);
    }
    return (
        <Dialog open={isOpen} onClose={() => handleChange()}>
        <div className="f-modal-action">
            <i
            className={`far fa-3x fa-times-circle `}
            onClick={() => handleChange()}
            />
        </div>
        <a href="https://cloud.digital.grupofamilia.com/Familia_Registro?o=Sitio">
            <img
            src={RegisterModal}
            alt="Imagen de registro en familia"
            width="100%"
            />
        </a>
        </Dialog>
    );
}

export default ModalRegister;